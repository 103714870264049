var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dp-pro-recipe-taste" },
    [
      _c("p", { staticClass: "dp-pro-recipe-taste__description" }, [
        _vm._v(" " + _vm._s(_vm.contents.capsule.description) + " "),
      ]),
      _c(
        "div",
        { staticClass: "dp-pro-recipe-taste__map" },
        [
          _c("DpTasteMap", {
            attrs: {
              "capsule-position": _vm.contents.capsule.map_position_id,
              "recipe-position": _vm.contents.map_position_id,
            },
          }),
        ],
        1
      ),
      _c("DpTasteMapLegend"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }